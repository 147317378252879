(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("angular"));
	else if(typeof define === 'function' && define.amd)
		define("ng-currency", ["angular"], factory);
	else if(typeof exports === 'object')
		exports["ng-currency"] = factory(require("angular"));
	else
		root["ng-currency"] = factory(root["angular"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE_angular__) {
return 